/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import { Empty, Modal, Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import _ from 'lodash';
import CallWindow from '../../Components/Connect/ConnectCallWindow/ConnectCallWindow';
import {
  getCallConversations,
  getOngoingCallId,
  getAllCallConversations,
} from '../../Reducers/CallConversationsReducer';
import config from '../../Config/Config';
import FetchContactInfo from '../../Components/Connect/FetchContactInfo/FetchContactInfo';
import { getCandidatesConnectInfo } from '../../Reducers/ConnectReducer';
import { getCallerIds } from '../../Reducers/ConnectSettingsReducer';
import { getApiStatus } from '../../Reducers/ApiStatusReducer';
import { getCurrentUser } from '../../Reducers/UserSessionReducer';
import * as ConnectActions from '../../Actions/ConnectActions';
import * as CallDialogActions from '../../Actions/CallDialogActions';
import { getCallDialogState } from '../../Reducers/CallDialogReducer';
import * as ConnectSettingsActions from '../../Actions/ConnectSettingsActions';
import * as CandidateConnectActions from '../../Actions/CandidateConnectActions';
import { getConnectUsersById, getUsersByGuId } from '../../Reducers/UserReducer';
import { getJobsBasicInfoById, getBasicInfoJobGuidToIdMapping } from '../../Reducers/JobsBasicInfoReducer';
import './CallWindow.scss';
import { getFeatureToggleList } from '../../Reducers/FeatureToggleReducer.ts';
import { getNonRefundedPhones } from '../../Utils/ContactUtils';
import { getCandidateOptOutReason } from '../../Utils/CandidateRejectReasonsUtils';
import { CallIcon } from '../../Icons/AryaIcons';
import { getCommunicationDisabledContent, getIsCandidateCommunicationDisabled } from '../../Utils/ConnectUtils';
import { UserAlertWithWrapper } from '../../Components/UserAlerts/UserAlerts';
import { getInternalSourceWithCandidateId } from '../../Utils/SourceUtils';
import message from '../CandidateDrawer/messages';

const mapStateToProps = (state, ownProps) => {
  const callDialogState = getCallDialogState(state);
  const featureToggleList = getFeatureToggleList(state);
  const isAllConversationsEnabled = _.get(featureToggleList, ['AllConversations', 'IsEnabled'], false);
  return {
    CallConversations: getCallConversations(state, ownProps.conversationId),
    allCallConversations: getAllCallConversations(state, ownProps.personId),
    CandidatesConnectInfo: getCandidatesConnectInfo(state),
    CallerIds: getCallerIds(state),
    CallerIdApiStatus: getApiStatus(state, 'fetchCallerIdStatus'),
    currentUser: getCurrentUser(state),
    connectUsersById: getConnectUsersById(state),
    isCallDialogVisible: callDialogState.isVisible,
    isCallInitiated: callDialogState.isCallInitiated,
    isSipRegistered: callDialogState.isSipRegistered,
    ongoingCallId: getOngoingCallId(state, ownProps.conversationId, ownProps.personId, isAllConversationsEnabled),
    featureToggleList,
    selectedCallerId: callDialogState.callerId,
    usersByGuId: getUsersByGuId(state),
    basicInfoJobGuidToIdMapping: getBasicInfoJobGuidToIdMapping(state),
    jobsBasicInfoById: getJobsBasicInfoById(state),
  };
};

const mapDispatchToProps = {
  addContact: ConnectActions.addContact,
  fetchCalls: ConnectActions.fetchCalls,
  setCallDialogVisibility: CallDialogActions.setCallDialogVisibility,
  setNotesViewState: CallDialogActions.setCallDialogNotesViewState,
  initiateCall: CallDialogActions.initiateCall,
  updateCallStatus: ConnectActions.updateCallStatus,
  setCallerId: CallDialogActions.setCallerId,
  addNewCallToConversation: ConnectActions.addNewCallToConversation,
  fetchCallerIds: ConnectSettingsActions.fetchCallerIds,
  unsetOngoingCallId: ConnectActions.unsetOngoingCallId,
  fetchCallNotes: CandidateConnectActions.fetchCallNotes,
};

class CallWindowContainer extends React.Component {
  static getDerivedStateFromProps(props, state) {
    let { selectedPhone, userCallerIds } = state;
    const { candidateId, jobCountryCode } = state;
    const {
      CandidatesConnectInfo,
      CallConversations,
      allCallConversations,
      conversationId,
      personId,
      candidate,
      CallerIds = [],
      setCallerId,
      currentUser = {},
      jobCountryCode: currentJobCountryCode,
    } = props;
    let currentPhones;
    const candidateConnectId = candidate.PersonId;
    const candidateConnectedInfo = _.get(CandidatesConnectInfo, ['ConnectStatuses', candidateConnectId]);
    const contact = _.get(candidateConnectedInfo, 'Contact', {});
    const phones = _.get(contact, 'Phones', []);
    const {
      ConsentStatus: consentStatus,
      ConsentConfiguration: consentConfiguration,
      Subscription: subscription,
    } = contact;
    const lastConversationWith = _.get(candidateConnectedInfo, ['AudioStatus', 'LastConversationWith'], null);
    if (jobCountryCode !== currentJobCountryCode) {
      const currentRegionCallerIds = CallerIds.filter(
        callerIdObject => callerIdObject.CountryCode === candidate.Country
      ).map(callerIdObject => callerIdObject.CallerId);
      const userPhoneNumber = currentUser.phone_number ? currentUser.phone_number.trim() : '';
      userCallerIds = userPhoneNumber ? [userPhoneNumber].concat(currentRegionCallerIds) : currentRegionCallerIds;
    }

    const candidateOptOutReason = getCandidateOptOutReason(candidate);

    if (candidate.Id !== candidateId) {
      let { displayName } = config.sipml;
      if (userCallerIds?.length) {
        userCallerIds = _.uniq(userCallerIds);
        [displayName] = userCallerIds;
      }
      setCallerId({ callerId: displayName });
    }
    let callHistory;
    if (conversationId) callHistory = CallConversations || [];
    else if (personId) callHistory = allCallConversations || [];
    if (selectedPhone === undefined || candidateId !== candidate.Id) {
      if (lastConversationWith) {
        selectedPhone = phones.find(p => p.Number === lastConversationWith);
      } else if (phones.length) {
        const nonRefundedPhones = getNonRefundedPhones(phones);
        if (selectedPhone) {
          currentPhones = nonRefundedPhones.filter(phone => {
            return phone.Number === selectedPhone.Number;
          });
        }
        [selectedPhone] = (currentPhones || []).concat(nonRefundedPhones);
      } else selectedPhone = undefined;
    }

    if (selectedPhone) {
      const phone = phones.find(p => p.Number === selectedPhone.Number);
      selectedPhone = {
        ...selectedPhone,
        ...phone,
      };
    }
    const isCommunicationAllowed =
      !(phones || []).some(phone => !phone.IsCommunicationAllowed) &&
      !candidateOptOutReason &&
      subscription?.Status?.toLowerCase() !== 'unsubscribed';

    const showFetchContact =
      !candidateConnectedInfo ||
      (!(getNonRefundedPhones(phones) || []).length &&
        (!consentStatus ||
          consentStatus === 'Approved' ||
          (consentConfiguration && consentConfiguration.IsContactViewAllowed)));

    return {
      callHistory,
      phones,
      selectedPhone,
      isCommunicationAllowed,
      contact,
      showFetchContact,
      candidateId: candidate.Id,
      userCallerIds,
      jobCountryCode: currentJobCountryCode,
    };
  }

  constructor(props) {
    super(props);
    this.state = {
      callLoading: false,
      callWarningModalVisible: false,
      isAlertOpen: true,
    };
    this.initiateCall = this.initiateCall.bind(this);
    this.onPhoneSelection = this.onPhoneSelection.bind(this);
    this.getAudioConversationId = this.getAudioConversationId.bind(this);
    this.setIsAlertOpen = this.setIsAlertOpen.bind(this);
  }

  componentDidMount() {
    const { fetchCalls, conversationId, fetchCallNotes, candidate, personId } = this.props;
    const { selectedPhone } = this.state;
    if ((conversationId || personId) && selectedPhone) {
      fetchCalls(conversationId, personId, null, 0, 10);
    }
    fetchCallNotes(candidate.Id, conversationId, 0, -1);
  }

  componentDidUpdate(prevProps) {
    const {
      fetchCalls,
      conversationId,
      candidate,
      isCallDialogVisible,
      setCallDialogVisibility,
      isCallInitiated,
      personId,
    } = this.props;
    const { selectedPhone } = this.state;
    if (prevProps.candidate.Id !== candidate.Id && (conversationId || personId) && selectedPhone) {
      fetchCalls(conversationId, personId, null, 0, 10);
      this.setState({ isAlertOpen: true });
    }
    if (
      !isCallDialogVisible &&
      _.isEqual(_.pick(prevProps.candidate, ['Id']), _.pick(candidate, ['Id'])) &&
      candidate.Id &&
      isCallInitiated
    ) {
      setCallDialogVisibility({ isVisible: true });
    }
  }

  onPhoneSelection(phoneValue) {
    const { conversationId, fetchCalls } = this.props;
    const {
      contact: { Phones: phones },
    } = this.state;
    const selectedPhone = (phones || []).find(p => p.Number === phoneValue) || null;
    this.setState({
      selectedPhone,
    });
    // if (conversationId && selectedPhone) {
    //   fetchCalls(conversationId, selectedPhone.Number);
    // }
  }

  openCallDialogPopup = () => {
    const { setCallDialogVisibility } = this.props;
    setCallDialogVisibility({ isVisible: true });
  };

  toggleCallbuttonLoading = () => this.setState(prevState => ({ ...prevState, callLoading: !prevState.callLoading }));

  toggleCallWarningModal = () =>
    this.setState(prevState => ({ ...prevState, callWarningModalVisible: !prevState.callWarningModalVisible }));

  selectCallerId = callerId => {
    const { setCallerId } = this.props;
    setCallerId({ callerId });
  };

  initiateCallArya() {
    const { selectedPhone, callerId } = this.state;
    const { conversationId, jobId, initiateCall, candidate, personId } = this.props;
    initiateCall({
      callInitiated: true,
      conversationId,
      personId,
      phoneNumber: selectedPhone.Number,
      callerId,
      personName: candidate.Name,
      candidateId: candidate.Id,
      jobId,
      candidate,
    });
    if (candidate.Id) {
      this.openCallDialogPopup();
    }
  }

  openNotesView = async () => {
    const { setNotesViewState } = this.props;
    await setNotesViewState(true);
  };

  async getAudioConversationId() {
    const { selectedPhone } = this.state;
    const { addNewCallToConversation, selectedCallerId, featureToggleList, candidate } = this.props;
    const isAllConversationsEnabled = _.get(featureToggleList, ['AllConversations', 'IsEnabled'], false);
    const call = {
      To: selectedPhone.Number,
      From: selectedCallerId,
    };
    try {
      return await addNewCallToConversation(
        candidate?.ConversationId,
        candidate?.PersonId,
        call,
        isAllConversationsEnabled
      );
    } catch (e) {
      this.toggleCallbuttonLoading();
      return null;
    }
  }

  async intiateAtsCall() {
    const { selectedPhone, callerId } = this.state;
    const {
      conversationId,
      jobId,
      candidate,
      openSipCallWindowsApp,
      selectedCallerId,
      candidateId,
      currentUser,
      currentJobDetails,
      fetchCalls,
      initiateCall,
      personId,
    } = this.props;
    try {
      await initiateCall({
        callInitiated: false,
        conversationId,
        personId,
        phoneNumber: selectedPhone.Number,
        callerId,
        personName: candidate.Name,
        candidateId: candidate.Id,
        jobId,
        candidate,
      });
    } catch (e) {
      this.toggleCallbuttonLoading();
      return null;
    }
    const payload = {
      conversationId,
      atsCandidateId: _.get(getInternalSourceWithCandidateId(_.get(candidate, ['Sources'], [])), ['CandidateId']),
      atsJobID: currentJobDetails.JobCode,
      fromNumber: selectedCallerId,
      toNumber: selectedPhone.Number,
      recruiterEmailId: currentUser.email,
      aryaJobId: `${jobId}`,
      aryaCandidteId: candidateId,
    };
    this.toggleCallbuttonLoading();
    openSipCallWindowsApp({
      sipPayload: payload,
      getAudioConversationId: this.getAudioConversationId,
      fetchCalls,
      toggleCallbuttonLoading: this.toggleCallbuttonLoading,
      toggleCallWarningModal: this.toggleCallWarningModal,
      openNotesPopUp: async () => {
        await this.openNotesView();
        this.openCallDialogPopup();
      },
    });
    return null;
  }

  initiateCall() {
    const { openSipCallWindowsApp, unsetOngoingCallId, conversationId, candidate, featureToggleList } = this.props;
    const isAllConversationsEnabled = _.get(featureToggleList, ['AllConversations', 'IsEnabled'], false);
    unsetOngoingCallId(candidate?.ConversationId, candidate?.PersonId, isAllConversationsEnabled);
    if (openSipCallWindowsApp) this.intiateAtsCall();
    else this.initiateCallArya();
  }

  setIsAlertOpen(value) {
    this.setState({ isAlertOpen: value });
  }

  render() {
    const {
      candidate,
      isCallInitiated,
      availableProviders,
      currentUser,
      connectUsersById,
      isCallDialogVisible,
      isSipRegistered,
      candidateStatus,
      featureToggleList,
      selectedCallerId,
      currentJobDetails,
      CandidatesConnectInfo,
      openSipCallWindowsApp,
      usersByGuId,
      unsubscribeCandidateButton,
      isUnsubscribeCandidateEnabled,
      connectComponent,
      personId,
      basicInfoJobGuidToIdMapping,
      jobsBasicInfoById,
      version,
      openCandidateView,
      openJobViewInNewTabCallBack,
      candidateContext,
    } = this.props;
    const {
      callHistory,
      selectedPhone,
      showFetchContact,
      contact,
      isCommunicationAllowed,
      userCallerIds,
      callLoading,
      callWarningModalVisible,
      isAlertOpen,
    } = this.state;
    const candidateName = candidate.Name;

    const isContactFetchEnabled = _.get(featureToggleList, ['ContactFetch', 'IsEnabled'], false);
    const showCommunicationDisabledAlert = getIsCandidateCommunicationDisabled(
      candidate.PersonId,
      CandidatesConnectInfo
    );

    if (showCommunicationDisabledAlert) {
      const alertContent = getCommunicationDisabledContent();
      return <UserAlertWithWrapper header={alertContent.header} content={alertContent.body} />;
    }

    if (showFetchContact) {
      return isContactFetchEnabled ? (
        <FetchContactInfo
          mediaType="call"
          candidateName={candidateName}
          availableProviders={availableProviders}
          candidate={candidate}
          contact={contact}
          currentJobDetails={currentJobDetails}
        />
      ) : (
        <div className="chat-empty">
          <Empty
            description={
              <div className="chat-empty-text">
                <FormattedMessage {...message.noCallHistoryAvailableLabel} />
              </div>
            }
            image={<CallIcon style={{ fontSize: '40px', color: '#CCC' }} />}
          />
        </div>
      );
    }

    return (
      <>
        <CallWindow
          callButtonEnabled={isSipRegistered && !isCallInitiated && isCommunicationAllowed}
          isCallDialogVisible={isCallDialogVisible}
          onCall={this.initiateCall}
          contact={contact}
          onPhoneSelection={this.onPhoneSelection}
          selectCallerId={this.selectCallerId}
          selectedCallerId={selectedCallerId}
          callerIds={userCallerIds}
          selectedPhone={selectedPhone}
          callHistory={callHistory}
          candidateName={candidateName}
          currentUser={currentUser}
          isCommunicationAllowed={isCommunicationAllowed}
          candidateStatus={candidateStatus}
          connectUsersById={connectUsersById}
          featureToggleList={featureToggleList}
          candidate={candidate}
          callLoading={callLoading}
          openSipCallWindowsApp={openSipCallWindowsApp}
          usersByGuId={usersByGuId}
          unsubscribeCandidateButton={unsubscribeCandidateButton}
          isUnsubscribeCandidateEnabled={isUnsubscribeCandidateEnabled}
          connectComponent={connectComponent}
          setIsAlertOpen={this.setIsAlertOpen}
          isAlertOpen={isAlertOpen}
          personId={personId}
          basicInfoJobGuidToIdMapping={basicInfoJobGuidToIdMapping}
          jobsBasicInfoById={jobsBasicInfoById}
          version={version}
          openCandidateView={openCandidateView}
          openJobViewInNewTabCallBack={openJobViewInNewTabCallBack}
          candidateContext={candidateContext}
        />
        <Modal
          zIndex={3000}
          visible={callWarningModalVisible}
          onOk={this.toggleCallWarningModal}
          onCancel={this.toggleCallWarningModal}
          footer={[
            <Button type="secondary" onClick={this.toggleCallWarningModal}>
              <FormattedMessage {...message.cancelButton} />
            </Button>,
            <Button key="submit" type="primary">
              <a href={config.urls.atsUserProfile} rel="noreferrer" target="_blank">
                <FormattedMessage {...message.addExtensionLabel} />
              </a>
            </Button>,
          ]}
        >
          <p>
            <FormattedMessage {...message.callingExtensionNumberLabel} />
          </p>
        </Modal>
      </>
    );
  }
}

export { CallWindowContainer as CallWindowWithoutContainer };
export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(CallWindowContainer);
