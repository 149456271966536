import React from 'react';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { Row, Col, Affix, Empty } from 'antd';
import CallHistoryItem from './CallHistoryItem';
import './CallHistory.scss';
import { CallIcon } from '../../Icons/AryaIcons';
import message from '../JobForm/JobMessages';

function CallHistory({
  callHistory,
  currentUser,
  connectUsersById,
  connectComponent,
  isUnsubscribeCandidateEnabled,
  unsubscribeCandidateButton,
  personId,
  basicInfoJobGuidToIdMapping,
  jobsBasicInfoById,
  version,
  openCandidateView,
  openJobViewInNewTabCallBack,
  candidate,
  isAllCallsAts,
  candidateContext,
}) {
  if (!callHistory) {
    return null;
  }
  let candidateContextLabel;
  if (candidateContext === 'job') candidateContextLabel = 'jobLabel';
  else candidateContextLabel = 'segmentLabel';
  const { Order: list, ByCallId: byId } = callHistory;
  if (!list || !byId) return null;
  return (
    <div
      className={classNames('call-history', {
        'call-history-connect': connectComponent,
        'all-call-history-ats': isAllCallsAts,
      })}
    >
      <div className="title">
        <FormattedMessage {...message.callHistoryLabel} />
      </div>
      <Affix>
        {!personId ? (
          <Row gutter={19} className="heading" type="flex" justify="space-between">
            <Col span={5}>
              <FormattedMessage {...message.statusLabel} />
            </Col>
            <Col span={5}>
              <FormattedMessage {...message.calledByLabel} />
            </Col>
            <Col span={7}>
              <FormattedMessage {...message.phoneNumber} />
            </Col>
            <Col span={4}>
              <FormattedMessage {...message.dateTimeLabel} />
            </Col>
          </Row>
        ) : (
          <Row gutter={15} className="heading" type="flex" justify="space-between">
            <Col span={4}>
              <FormattedMessage {...message.statusLabel} />
            </Col>
            <Col span={6}>
              <FormattedMessage {...message[candidateContextLabel]} />
            </Col>
            <Col span={4}>
              <FormattedMessage {...message.calledByLabel} />
            </Col>
            <Col span={6}>
              <FormattedMessage {...message.phoneNumber} />
            </Col>
            <Col span={3}>
              <FormattedMessage {...message.dateTimeLabel} />
            </Col>
          </Row>
        )}
      </Affix>
      {list.length ? (
        <div className="list">
          {list.map(id => (
            <CallHistoryItem
              key={id}
              callDetails={byId[id]}
              createdUser={_.get(connectUsersById, byId[id].CreatedBy, {})}
              refId={byId[id]?.RefId}
              personId={personId}
              basicInfoJobGuidToIdMapping={basicInfoJobGuidToIdMapping}
              jobsBasicInfoById={jobsBasicInfoById}
              version={version}
              openCandidateView={openCandidateView}
              openJobViewInNewTabCallBack={openJobViewInNewTabCallBack}
              candidate={candidate}
            />
          ))}
        </div>
      ) : (
        <div>
          <Empty
            description={<FormattedMessage {...message.noCallHistoryAvailableLabel} />}
            image={<CallIcon className="empty-call-icon" />}
          />
        </div>
      )}
      {list.length > 0 && !connectComponent && (
        <Affix offsetBottom={0}>
          <span className="unsubscribe-button-candidate-card-call-history">
            {isUnsubscribeCandidateEnabled ? unsubscribeCandidateButton : null}
          </span>
        </Affix>
      )}
    </div>
  );
}

export default CallHistory;
